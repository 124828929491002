import React from 'react';
import Routes from './routes/';
import { Helmet } from "react-helmet"
import config from "./config_empresa"
import PullToRefresh from 'react-simple-pull-to-refresh';
import { isAndroid } from 'react-device-detect';
import { CircularProgress } from '@mui/material';

//Import Scss
import "./assets/scss/themes.scss";

// import PullToRefreshComponent from './components/PullToRequest';
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.substr(1);
}

const handleRefresh = () => {
  return new Promise(() => window.location.reload());
};

function App() {
  return (
    <>
      <Helmet>
        <title>{capitalize(config.EMPRESA)} - Chat</title>
      </Helmet>
      {/* <PullToRefreshComponent Component={Routes} /> */}
      <PullToRefresh
        isPullable={isAndroid}
        pullingContent={""}
        refreshingContent={<CircularProgress size={35} color='primary' />}
        onRefresh={handleRefresh}
      >
        <Routes />
      </PullToRefresh>
    </>
  );
}

export default App;
