import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebSocketProvider from './WebSocketProvider';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { requestPermission } from './helpers/Notification'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://7877be034f20193b099062910a954562@o4508200674852864.ingest.us.sentry.io/4508201953722368",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https?:\/\/.*/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <WebSocketProvider>
      <App />
    </WebSocketProvider>
  </Provider>,
  document.getElementById('root')
);


// request permission notification brownser
requestPermission()
  .then(success => console.log(success))
  .catch(err => console.log(err))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
