import { v4 as uuidv4 } from "uuid";

const HOST = "https://api.multdesk.com.br/push"
const publicVapidKey = "BJOejF-ckE9eZo3DR9P5ogNEUYOi6-xZ1ekCM_02nyQXLVC2Ewn4DvoYj6eo54A_S1nOizvmhmbGNXaYW3WhcMo";

export const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
            return permission
        }

        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export const showNotification = async (data) => {
    const options = {
        body: data.body,
        icon: "public/android-chrome-512x512.png",
        dir: "ltr",
        data: {
            primaryKey: uuidv4()
        }
    };

    try {

        if ('serviceWorker' in navigator) {

            const permission = await requestPermission()
            if (!permission) return "Sem permissão!"

            const registration = await navigator.serviceWorker.ready
            const subscription = await registration.pushManager.getSubscription()

            // se tem push notification
            if (subscription) {
                console.log("Tem subscription: ", subscription.endpoint)
                return registration.showNotification(data.title, options);
            }

            // se não tem..
            console.log("Não tem subscription!")
            return new Notification(data.title, options);

        } else {
            console.log("Não tem service worker!")
            return new Notification(data.title, options);
        }
    } catch (e) {
        console.log(e)
        new Notification(data.title, options);
    }
}

export const getPermissionNotifications = async () => {
    try {
        return Notification.permission
    } catch (e) {
        console.error(e)
        return false
    }
}

export const getSubscribePushNotification = async () => {
    try {
        if (!('serviceWorker' in navigator)) return false
        const registration = await navigator.serviceWorker.ready
        const subscription = await registration.pushManager.getSubscription()
        return subscription
    } catch (e) {
        return false
    }
}

export const getSubscribePushNotificationMobile = async (user, device_id) => {
    try {

        const body = {
            user: user,
            device_id
        }

        const response = await fetch(HOST + "/get-subscribe-mobile", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json"
            }
        })

        return await response.json()

    } catch (e) {
        return false
    }
}

export const unsubscribePushNotificationMobile = async (user, device_id) => {
    try {
        const body = {
            user: user,
            device_id
        }
        const response = await fetch(HOST + "/unsubscribe-mobile", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json"
            }
        })
        return await response.json()
    } catch (e) {
        return false
    }
}

export const unsubscribePushNotification = async (user, device_id) => {
    try {
        if (!('serviceWorker' in navigator)) return { error: true, message: "Service worker não encontrado!" }
        const registration = await navigator.serviceWorker.ready
        const subscription = await registration.pushManager.getSubscription()

        const body = {
            subscription: subscription,
            user: user,
            device_id
        }

        await fetch(HOST + "/unsubscribe", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json"
            }
        })

        await subscription.unsubscribe()

        return { error: false, message: "Sucesso ao desativar notificações!" }
    } catch (e) {
        console.log(e)
        return { error: true, message: "Erro ao desativar notificações!" }
    }
}

export const subscribeUserToPush = async (user, device_id) => {
    if (!('serviceWorker' in navigator)) return { error: true, message: "Service worker não encontrado!" }
    const registration = await navigator.serviceWorker.ready
    const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    };

    const subscription = await registration.pushManager.subscribe(subscribeOptions)
    console.log('Received PushSubscription: ', subscription.endpoint);

    const body = {
        subscription: subscription,
        user: user,
        device_id
    }

    await fetch(HOST + "/subscribe", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "content-type": "application/json"
        }
    })

    return subscription
}

export const getPermissionSoundNotification = () => {
    const permission = localStorage.getItem('sound_notification')

    if (!permission) {
        localStorage.setItem('sound_notification', "granted")
        return "granted"
    }

    return permission
}

export const handleChangeSoundNotification = () => {
    const permission = localStorage.getItem('sound_notification')
    if (permission == "granted") {
        localStorage.setItem('sound_notification', 'dismiss')
        return 'dismiss'
    } else {
        localStorage.setItem('sound_notification', 'granted')
        return 'granted'
    }
}

export const checkBannerPermissions = () => {
    const banner_permission = localStorage.getItem('banner_permission')
    if (!banner_permission) return true
    return false
}

export const setBannerPermission = () => {
    localStorage.setItem("banner_permission", "true")
}

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


export const subscribePushNotificationMobile = (user, device_id, token, userId) => {
    try {
        // chama o react-native
        window.ReactNativeWebView.postMessage(JSON.stringify({
            type: "subscribe-push-notification",
            user,
            device_id,
            token,
            userId
        }));
    } catch (e) {
        console.log(e)
    }
}