const config = {
    API_URL: "https://api.multdesk.com.br/",
    URL_SOCKET: "https://websocket.multdesk.com.br",
    // API_URL: "https://hmlapi.multdesk.com.br/",
    // URL_SOCKET: "https://hmlwebsocket.multdesk.com.br",
    // API_URL: "https://api.zapresponder.com.br/",
    // URL_SOCKET: "https://websocket.zapresponder.com.br",
    // API_URL: "http://localhost:3001/",
    // URL_SOCKET: "http://localhost:3002/",
    STORAGE_URL: "https://storage.multdesk.com.br/",
    APP_URL: "https://app.multdesk.com.br",
    AUTH_BACKEND: 'Dummy'
}

export default config